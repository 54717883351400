import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import './App.css';

function App() {
  const [handle, setHandle] = useState('');
  const [didPlc, setDidPlc] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(Date.now());
  
  const reCAPTCHARef = useRef();
  const data = "";

  // Handle the data inputs for the form
  const handleInputChange = (e) => {
    setHandle(e.target.value.toLowerCase());
  };
  const didPlcInputChange = (e) => {
    setDidPlc(e.target.value.toLowerCase());
  };
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  // On form submit processing
  const handleSubmit = async () => {
    
    // Did we get any handle entry?
    if (!handle) {
      handleErrorResponse('BLANK_HANDLE');
      return;
    }
    
    // Check we're not exceeding the alphanumeric 20 character max limit
    if(!/^[a-zA-Z0-9]{1,20}$/.test(handle.toLowerCase())) {
      handleErrorResponse('INVALID_HANDLE');
      return;
    }
    
    // Did we get any did:plc entry?
    if (!didPlc) {
      handleErrorResponse('BLANK_DID_PLC');
      return;
    } 
    
    // Is it a valid bluesky did:plc value?
    // TODO: sanity check against whether the profile actually exists to stop idiots like Raigho from trying to break it with random bot level shit
    if (!/^did:plc:[a-zA-Z0-9]{24}$/.test(didPlc.toLowerCase())) {
      handleErrorResponse('INVALID_DID_PLC');
      return
    }
    
    // reeeeeeeeeeCaptcha
    if (!recaptchaValue) {
      handleErrorResponse('RECAPTCHA_INCOMPLETE');
      return;
    }

    try {	  
      const response = await axios.post('/verifyHandle', {
        handle,
        didPlc,
        recaptchaValue,
      });

      const data = response.data;

      if (data.success) {
        setMessage('Verification Complete! Now head over to bluesky and hit "Verify Text File" to finish!');
        setHandle('');
        setDidPlc('');
		reCAPTCHARef.current.reset();
        setRecaptchaValue(Date.now());
      } else {
        handleErrorResponse(data.error);
        reCAPTCHARef.current.reset();
        setRecaptchaValue(Date.now());
      }
    } catch (error) {
      handleErrorResponse(data.error);
      reCAPTCHARef.current.reset();
      setRecaptchaValue(Date.now());
    }
  };

  const handleErrorResponse = (error) => {
    switch (error) {
      case 'BLANK_HANDLE':
        setMessage('Please enter a handle you would like to use.');
        break;
      case 'INVALID_HANDLE':
        setMessage('Invalid handle. Must be alphanumeric (letters and numbers only) and no longer than 20 characters.');
        break;
      case 'BLANK_DID_PLC':
        setMessage('Please enter your did:plc provided from bluesky. If you need help, please see below.');
        break;
      case 'INVALID_DID_PLC':
        setMessage('Invalid bluesky did:plc code.');
        break;
      case 'RECAPTCHA_INCOMPLETE':
        setMessage('Please complete the reCAPTCHA.');
        break;
      case 'RECAPTCHA_FAILED':
        setMessage('reCAPTCHA verification failed.');
        break;
	  case 'HANDLE_TAKEN':
	    setMessage('Sorry this handle is already in use. Please choose another.');
		break;
      case 'FILE_WRITE_ERROR':
        setMessage('An error occurred while verifying your handle.');
        break;
      default:
        setMessage('An error occurred.');
        break;
    }
  };
  
  return (
                    <div>
                        <div className="mb-4 text-center"><label className="form-label staatliches">Choose your handle</label><span className="wolfbear-club staatliches red at">&#64;</span><input type="text" className="form-input w-handle" value={handle} onChange={handleInputChange} maxLength={24} pattern="[a-zA-Z0-9]+" /><span className="wolfbear-club staatliches">.<span className="red">wolfbear</span>.<span className="red">club</span></span></div>
                        <div className="mb-4 text-center"><label className="form-label">Enter your Bluesky DID:PLC CODE</label><input type="text" className="form-input w-100" value={didPlc} onChange={didPlcInputChange} maxLength={32} pattern="^did:plc:[a-zA-Z0-9]+$" /><span className="help">( <a href="#how-to">What is my DID:PLC code?</a> )</span></div>
                        <div className="mb-4 text-center"><ReCAPTCHA className="recaptcha" ref={reCAPTCHARef} sitekey="6Lc3zg0oAAAAABkBPUiAXvY9od4DRJGXE_d7SFb8" onChange={handleRecaptchaChange} /></div>
                        <div className="text-center"><button className="btn btn-primary" type="button" onClick={handleSubmit}>Start verification</button></div>
                        <div className="form-code-messages my-4 py-2 text-center"><span>{message}</span></div> 
                   </div>
  );
}

export default App;
